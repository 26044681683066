@import "./mixins/grid";

.bg-dark {
    background-color: var(--bs-dark) !important;
}

.bg-danger {
    background-color: var(--bs-danger) !important;
}

.text-danger {
    color: var(--bs-danger) !important;
}

.text-warning {
    color: var(--bs-warning) !important;
}

.text-muted {
    color: var(--bs-secondary-color) !important;
}

.navbar {
    --bs-navbar-color: var(--bs-danger);
    --bs-navbar-hover-color: white;
    --bs-navbar-active-color: white;
}

.dropdown-menu {
    --bs-dropdown-border-radius: 0;
    --bs-dropdown-bg: var(--bs-dark2);
    --bs-dropdown-min-width: 60px;
    --bs-dropdown-link-color: var(--bs-danger) !important;
    --bs-dropdown-link-active-bg: var(--bs-dark);
}

.btn-warning {
    --bs-btn-bg: var(--bs-warning);
    --bs-btn-color: var(--bs-dark);
    --bs-btn-border-radius: 9999px;
    --bs-btn-border-color: var(--bs-warning);
    --bs-btn-hover-border-color: var(--bs-warning);
    --bs-btn-active-border-color: var(--bs-warning);
    --bs-btn-hover-bg: var(--bs-warning2);
    --bs-btn-active-bg: var(--bs-warning);
    --bs-btn-padding-x: 1.5rem;
}

.btn-danger {
    --bs-btn-bg: var(--bs-danger);
    --bs-btn-color: var(--bs-dark);
    --bs-btn-border-color: var(--bs-danger);
    --bs-btn-hover-border-color: var(--bs-danger);
    --bs-btn-active-border-color: var(--bs-danger);
    --bs-btn-hover-bg: var(--bs-danger2);
    --bs-btn-active-bg: var(--bs-danger);
    --bs-btn-padding-x: 1.5rem;
}

.btn-outline-warning {
    --bs-btn-color: var(--bs-warning);
    --bs-btn-border-color: var(--bs-warning);
    --bs-btn-hover-border-color: var(--bs-warning);
    --bs-btn-hover-bg: var(--bs-warning);
}

.btn-outline-danger {
    --bs-btn-color: var(--bs-danger);
    --bs-btn-border-color: var(--bs-danger);
    --bs-btn-hover-border-color: var(--bs-danger);
    --bs-btn-hover-bg: var(--bs-danger);
    --bs-btn-active-bg: var(--bs-danger2);
    --bs-btn-active-border-color: var(--bs-danger);
}

.navbar-toggler {
    outline: none !important;
    box-shadow: none !important;
}

@include media-breakpoint-down(lg) {
    .navbar-collapse {
        z-index: 10;
        transition: left 0.3s linear;
        background: var(--bs-dark);
        position: fixed;
        top: 0;
        bottom: 0;
        left: -100vw;
        width: 100vw;
        padding: 3rem;
        display: block !important;
        height: auto !important;

        a {
            padding-bottom: 0;
        }

        &.show {
            left: 0;
        }
    }
}

.nav-pills {
    .nav-item {
        --bs-nav-pills-border-radius: 0;
        --bs-nav-link-color: #fff;
        --bs-nav-link-hover-color: var(--bs-danger2);
        --bs-nav-pills-link-active-bg: var(--bs-danger);

        .nav-link {
            border: 1px solid var(--bs-danger);
        }
    }
}

.carousel-caption.has-photo {
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(2px);
}

.w-lg-25 {
    @include media-breakpoint-up(lg) {
        width: 25% !important;
    }
}