@import "./mixins/grid";

:root {
  --bs-danger: #9F554A;
  --bs-danger2: #b15f52;

  --bs-dark: #0C0B08;
  --bs-dark2: #2c2020;
  --bs-warning: #D08A4C;
  --bs-warning2: #dd914f;
  --bs-secondary-color: #AAAAAA;
}

html, body {
  background: var(--bs-dark);
  font-size: 18px;
  letter-spacing: 0.04em;
  font-family: 'PT Sans', sans-serif;
}

.suspense-loading {
  padding-top: calc(50vh - 78px);
}

.cursor-pointer {
  cursor: pointer;
}

.languages {
  .dropdown-menu {
    right: auto;
    left: 50%;
    margin-left: -37px;
    text-align: center;
    top: -8px;
  }
}

.logo-img {
  height: 60px;
}

.nav-vr-line {
  width: 1px;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 65%, rgba(255,255,255,0) 100%);
}

.img {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.own-breadcrumb {
  height: 250px;
  background-image: url("../img/bg1.png");

  @include transparent-bg();
}

.own-form {
  --line-color: white;

  // border: 1px solid var(--line-color);
  .sign-margin {
    margin-bottom: -40px;
  }

  .ff-h {
    letter-spacing: 0.03em;
    font-size: min(120px, 15vw);
  }

  @include media-breakpoint-down(lg) {
    .sign-margin {
      margin-bottom: -20px;
    }
  }

  @include media-breakpoint-up(lg) {
    .own-form__container {
      width: 50%;
    }
  }

  .loading-bg {
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
  }

  .react-date-picker__wrapper {
    padding: .375rem .75rem;
  }

  .react-date-picker__wrapper,
  .form-select,
  .react-tel-input .form-control,
  .form-control-plaintext,
  .form-control {
    border: 1px solid var(--line-color);
    border-radius: 0;
    background-color: var(--bs-danger);
    box-shadow: none;
    outline: none;
    color: white;

    &::placeholder {
      color: rgb(255, 195, 195);
    }
  }

  .form-check-label {
    color: #fff;
  }

  .form-control-plaintext {
    padding-left: 0.5rem;
  }

  .react-tel-input .form-control {
    font-size: 1rem;
    line-height: 1.5;
    display: block;
    width: 100%;
    box-sizing: border-box !important;
    height: auto;
  }

  .react-tel-input {
    .flag-dropdown  {
      border-radius: 0px;
      top: 1px;
      left: 1px;
      bottom: 1px;
    }
  }

  .invalid-feedback {
    font-size: 0.75rem;
    color: #fff;
  }

  .flag-dropdown  {
    border-color: var(--bs-danger) !important;

    .selected-flag {
      background: var(--bs-danger) !important;
      border-radius: 0 !important;

      &:hover {
        background: var(--bs-danger2) !important;
      }
    }

    .country-list {
      background: #fff;

      .dial-code {
        color: var(--bs-dark) !important;
      }

      .country {
        &.highlight,
        &:hover {
          background: var(--bs-dark);

          &, .dial-code {
            color: white !important;
          }
        }
      }
    }
  }

  .input-group {
    .btn {
      border-radius: 0;
    }
  }

  .react-date-picker {
    display: flex;
  }

  .react-date-picker__wrapper {
    .react-date-picker__button {
      padding: 0 5px;

      &:hover svg,
      &:active svg,
      &:visited svg,
      &:focus svg{
        stroke: white !important;
      }
    }

    input {
      color: white;
    }

    svg {
      stroke: white;
    }
  }
}

.img-container {
  .show-more {
    backdrop-filter: blur(2px);
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
  }

  .img, .show-more {
    transition: all linear 0.3s;
  }

  &:hover {
    .img, .show-more {
      transform: scale(1.2);
    }

    .show-more {
      opacity: 1;
    }
  }
}

.no-img {
  > div {
    border: 1px solid #333;
  }

  &.no-img-white > div {
    color: #fff;
    border-color: #fff;
  }
}

.fluid-video {
  padding-top: 56.25%;

  @include media-breakpoint-up('lg') {
    padding-top: 35%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.react-calendar {
  background: white;
}

.react-calendar__tile--now {
  background: var(--bs-danger) !important;
  color: white !important;
}

.react-calendar__month-view__days__day--weekend {
  color: var(--bs-danger) !important;
}

.react-calendar__tile--active {
  background-color: black !important;
  color: white !important;
}

.no-data-found {

}

.react-date-picker__calendar {
  z-index: 1000 !important;
}