$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

@import "~bootstrap/scss/mixins/breakpoints.scss";

@mixin transparent-bg {
    position: relative;
    
    &::before {
        content: "";
        z-index: 0;
        position: absolute;
        background: rgba(0, 0, 0, 0.7);
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }
}
