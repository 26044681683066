@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');

@font-face {
    font-family: 'AA Bebas Neue';
    src: url('../fonts/AABebasNeue.woff2') format('woff2'),
        url('../fonts/AABebasNeue.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'DS Arabic';
    src: url('../fonts/DSArabic.woff2') format('woff2'),
        url('../fonts/DSArabic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



.ff-link {
    font-family: 'AA Bebas Neue', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.ff-h {
    font-family: 'DS Arabic', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}